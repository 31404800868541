import React, { useEffect } from "react";
import Router from "next/router";

import { useDispatch } from "react-redux";
import { setUserData, setCompetitionId, setCompetitionPath, setParticipantId } from "@redux/actions";
import * as CookiesUtil from "@util/cookies";
import { getAccessTokenWithRefreshToken } from "@util/api/auth";
import { updateProfileState } from "@util/api/profile";
import { FUSION_ACCESS_TOKEN_EXPIRE_MINUTES } from "@util/constants";
import { IServerSideContext } from "./interfaces";
import cookie from "cookie";

import { base64url } from "rfc4648";
import { session_ended_toast_message } from "@util/strings";

const jwKey = {
    alg: "ES256",
    crv: "P-256",
    kid: "9DGP2kwds_TXTw_skfBKBV1XWPU",
    kty: "EC",
    use: "sig",
    x: "LgqN4awlOpxO_LvxWLN5FgMwRB7GK53orTydWv_sLDY",
    x5c: [
        "MIIBKzCB06ADAgEBAhEA8pR8u8/tRD2lTSdtWm0K3DAKBggqhkjOPQQDAjAYMRYwFAYDVQQDEw1yb2JvZXBpY3MuY29tMB4XDTIyMTIwMzIxMTY1OFoXDTMyMTIwMzIxMTY1OFowGDEWMBQGA1UEAxMNcm9ib2VwaWNzLmNvbTBZMBMGByqGSM49AgEGCCqGSM49AwEHA0IABC4KjeGsJTqcTvy78VizeRYDMEQexiud6K08nVr/7Cw2rsoa6ZGsn/GNAmAXxQ7WsC2no0uECLKcKFFnPclhcEUwCgYIKoZIzj0EAwIDRwAwRAIgFOkz35pRpndbuFmSGdHcz/hodajXlXqluDLTCJgibUkCIG/rYkcHeIE3sUMRDtN3Xu3jka+TDzxg9D9qcL3eMuQj",
    ],
    x5t: "9DGP2kwds_TXTw_skfBKBV1XWPU",
    "x5t#S256": "XgiiN_TrKuUJMDxko-suj6fPR6tQFmfK-nauxvQW-Mg",
    y: "rsoa6ZGsn_GNAmAXxQ7WsC2no0uECLKcKFFnPclhcEU",
}; // TODO retrieve and cache from https://fusion.roboepics.com/.well-known/jwks.json

const algorithm = {
    name: "ECDSA",
    namedCurve: "P-256",
    hash: "SHA-256",
};

let key;

async function getKey() {
    if (!key) {
        key = await crypto.subtle.importKey("jwk", jwKey, algorithm, false, ["verify"]);
    }
    return key;
}

export default (props: { children: React.ReactElement }): React.ReactElement => {
    const dispatch = useDispatch();

    useEffect(() => {
        try {
            console.log(`
            8888888b.          888               8888888888          d8b                   
            888   Y88b         888               888                 Y8P                   
            888    888         888               888                                       
            888   d88P .d88b.  88888b.   .d88b.  8888888    88888b.  888  .d8888b .d8888b  
            8888888P" d88""88b 888 "88b d88""88b 888        888 "88b 888 d88P"    88K      
            888 T88b  888  888 888  888 888  888 888        888  888 888 888      "Y8888b. 
            888  T88b Y88..88P 888 d88P Y88..88P 888        888 d88P 888 Y88b.         X88 
            888   T88b "Y88P"  88888P"   "Y88P"  8888888888 88888P"  888  "Y8888P  88888P' 
                                                            888                            
                                                            888                            
                                                            888                            `);
            console.log("RoboEpics Front-End V2 Build 3/01");
            if (!CookiesUtil.getCookie("re_hcbd")) {
                CookiesUtil.setCookie("re_hcbd", "false", "session", "strict");
            }
            const cookie_access_token = CookiesUtil.getCookie("re_auth_access_token");
            const cookie_refresh_token = CookiesUtil.getCookie("re_auth_refresh_token");
            if (!cookie_access_token || !cookie_refresh_token) {
                // No cookies. Must log in

                // Clearing redux previous values
                dispatch(setCompetitionId(null)); // FIXME do we still need this or path is enough?
                dispatch(setCompetitionPath(null));
                dispatch(setParticipantId(null));
                dispatch(setUserData({ id: "", fullName: "", imageUrl: "", username: "", email: "", isSuperuser: false }));
            } else {
                refreshAccessToken().then(() => updateProfileState());
            }
        } catch (e) {
            console.log("Error in auth handler");
        }
    }, []);
    return props.children;
};

export const refreshAccessToken = async (
    ctx?: IServerSideContext,
    needsRedirect = false,
): Promise<{ accessToken: string; refreshToken: string } | null> => {
    let refreshToken: string, accessToken: string;

    const daysToAdd = 90;
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + daysToAdd);

    if (ctx?.req?.cookies && ctx.req.cookies["re_auth_refresh_token"]) {
        // Server-side
        refreshToken = ctx.req.cookies["re_auth_refresh_token"];
        accessToken = ctx.req.cookies["re_auth_access_token"];
        // console.log("refreshToken in serverside before going through checking: ", refreshToken);
    } else if (!ctx?.req && CookiesUtil.getCookie("re_auth_refresh_token")) {
        // Client-side
        refreshToken = CookiesUtil.getCookie("re_auth_refresh_token");
        accessToken = CookiesUtil.getCookie("re_auth_access_token");
    } else {
        // Not logged in
        if (needsRedirect) {
            if (ctx?.res && ctx?.req) {
                console.log("-------- redirecting to login because we don't have cookies ------");
                ctx.res.writeHead(302, {
                    Location: `/users/signin${ctx.req.url ? `?returnUrl=${ctx.req.url}` : ""}`, // TODO encodeUrl the request URL
                });
                ctx.res.end();
            } else {
                // There was something wrong with refreshing the token, so we redirect to sign-in
                ctx.toast({
                    description: session_ended_toast_message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                    variant: "subtle",
                });
                setTimeout(() => {
                    Router.push(`/users/signin${ctx.requesterPath ? `?returnUrl=${ctx.requesterPath}` : ""}`); // TODO encodeUrl the requesterPath
                }, 1500);
            }
        }
        return null;
    }

    try {
        const jwsObjectSplitted = accessToken.split(".");
        const jwsSigningInput = jwsObjectSplitted.slice(0, 2).join(".");
        const jwsSignature = jwsObjectSplitted[2];

        // Verify token signature
        const isValid = await crypto.subtle.verify(
            algorithm,
            await getKey(),
            base64url.parse(jwsSignature, { loose: true }),
            new TextEncoder().encode(jwsSigningInput),
        );
        if (!isValid) throw Error("Token is invalid!");

        // Verify token expiration
        const expire = JSON.parse(Buffer.from(jwsObjectSplitted[1], "base64").toString("utf8")).exp;
        if (new Date().getTime() > expire * 1000) throw Error("Token is expired!");
    } catch (e) {
        const response = await getAccessTokenWithRefreshToken(refreshToken, accessToken);
        // getAccessWithRefreshToken could not get an access token, so we redirect to sign-in;
        if (response == null) {
            // Refresh token has expired or FusionAuth is down
            if (needsRedirect) {
                if (ctx?.res && ctx?.req) {
                    console.log("-------- redirecting to login after retrying to get new access token in RoboEpicsAuthHandler file ------");
                    ctx.res.writeHead(302, {
                        Location: `/users/signin${ctx.req.url ? `?returnUrl=${ctx.req.url}` : ""}`,
                    });
                    ctx.res.end();
                } else {
                    // There was something wrong with refreshing the token, so we redirect to sign-in
                    if (ctx && ctx.toast) {
                        ctx.toast({
                            description: session_ended_toast_message,
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                            variant: "subtle",
                        });
                        setTimeout(() => {
                            Router.push(`/users/signin${ctx.requesterPath ? `?returnUrl=${ctx.requesterPath}` : ""}`);
                        }, 1500);
                    }
                }
            }
            return null;
        }

        const { access_token, refresh_token } = response;
        accessToken = access_token;
        refreshToken = refresh_token;
    }

    const tokenExpire = String(new Date().getTime() + FUSION_ACCESS_TOKEN_EXPIRE_MINUTES * 60 * 1000);

    // Set tokens in cookies
    if (ctx?.req?.cookies) {
        // if we are in server-side
        ctx.req.cookies["re_auth_refresh_token"] = refreshToken;
        ctx.req.cookies["re_auth_access_token"] = accessToken;
        ctx.req.cookies["re_auth_access_token_expire"] = tokenExpire;

        ctx.res.setHeader("set-cookie", [
            cookie.serialize("re_auth_refresh_token", refreshToken, {
                expires: expireDate,
                sameSite: "strict",
                path: "/",
                domain: `${process.env.NODE_ENV === "development" ? "" : ".roboepics.com"}`,
            }),
            cookie.serialize("re_auth_access_token", accessToken, {
                expires: expireDate,
                sameSite: "strict",
                path: "/",
                domain: `${process.env.NODE_ENV === "development" ? "" : ".roboepics.com"}`,
            }),
            cookie.serialize("re_auth_access_token_expire", tokenExpire, {
                expires: expireDate,
                sameSite: "strict",
                path: "/",
                domain: `${process.env.NODE_ENV === "development" ? "" : ".roboepics.com"}`,
            }),
        ]);
    } else {
        // if we are in client-side
        CookiesUtil.setCookie("re_auth_access_token", accessToken, 90, "strict");
        CookiesUtil.setCookie("re_auth_refresh_token", refreshToken, 90, "strict");
        CookiesUtil.setCookie("re_auth_access_token_expire", tokenExpire, 90, "strict");
    }

    return { accessToken, refreshToken };
};
