import { createSlice } from "@reduxjs/toolkit";

export interface IColorMode {
    mode: string;
}

const initialState: IColorMode = {
    mode: "light",
};

const colorModeSlice = createSlice({
    name: "_ui",
    initialState,
    reducers: {
        setColorMode: (state, data) => {
            state.mode = data.payload;
        },
    },
});

export const { setColorMode } = colorModeSlice.actions;

export default colorModeSlice.reducer;
