import { createSlice } from "@reduxjs/toolkit";

export interface IUser {
    data: {
        fullName: string;
        email: string;
        id: string;
        username: string;
        imageUrl: string;
        isSuperuser: boolean;
    };
}

const initialState: IUser = {
    data: {
        id: "",
        fullName: "",
        email: "",
        username: "",
        imageUrl: null,
        isSuperuser: false,
    },
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserData: (state, data: { payload: IUser["data"]; type: string }) => {
            state.data = {
                id: data.payload.id,
                fullName: data.payload.fullName,
                email: data.payload.email,
                username: data.payload.username,
                imageUrl: data.payload.imageUrl,
                isSuperuser: data.payload.isSuperuser,
            };
        },
    },
});

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
