export default {
    customBadge: {
        baseStyle: ({ colorMode }) => ({
            padding: "1rem",
        }),
    },
    sizes: {},
    variants: {},
    defaultStyle: {},
};
