/**
 * Sets the given cookie.
 * @param {string} name - Name of the cookie.
 * @param {string} value - Value of the cookie.
 * @param {number | "session"} expiry - Number of days for the cookie to expire or the string "session".
 * @param {"lax" | "strict"} sameSite - The string representing the same-site policy. Can only be "lax" or "strict".
 */
export const setCookie = (name: string, value: string, expiry: number | "session", sameSite: "lax" | "strict"): void => {
    let expires = "";
    if (expiry) {
        if (expiry === "session") expires = "; Expires=" + expiry;
        else {
            const date = new Date();
            date.setTime(date.getTime() + (expiry as number) * 24 * 60 * 60 * 1000);
            expires = "; Expires=" + date.toUTCString();
        }
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/${
        process.env.NODE_ENV === "development" ? "" : "; Domain=.roboepics.com"
    }; Samesite=${sameSite}`;
};

export const getCookie = (name: string): string | null => {
    name += "=";
    for (let c of document.cookie.split(";")) {
        c = c.trimStart();
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return null;
};

export const eraseCookie = (name: string): void => {
    document.cookie =
        name +
        "=; Path=/" +
        `${process.env.NODE_ENV === "development" ? "" : "; Domain=.roboepics.com"}` +
        ";" +
        "Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
