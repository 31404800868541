import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtl from "stylis-plugin-rtl";
const options = {
    rtl: { key: "css-ar", stylisPlugins: [rtl] },
    ltr: { key: "css-en" },
};
const EmotionRtlProvider = ({ children }) => {
    const cache = createCache(options["ltr"]);
    return <CacheProvider value={cache} children={children} />;
};

export default EmotionRtlProvider;
