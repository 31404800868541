// #region Global Imports
import { DefaultTheme } from "styled-components";
// #endregion Global Imports
import { common } from "./common";

const light = {
    colors: {
        ...common.colors,
        toggleBorder: "#ABB7C4",
        gradient: "linear-gradient(#39598A, #79D7ED)",
        background: "#282C34",
        headerBg: "#20232A",
        cardsBg: "#FFFFFF",
        textColor: "#000000",
        dodgerBlue: "#007BFF",
        RoboEpics: {
            black: {
                default: "#000",
                900: "#18181B",
                800: "#343a40",
                700: "#495057",
                600: "#6c757d",
                500: "#adb5bd",
                400: "#ced4da",
                300: "#dee2e6",
                200: "#e9ecef",
                100: "#f8f9fa",
            },
            azure: {
                100: "#caf0f8",
                200: "#ade8f4",
                300: "#90e0ef",
                400: "#48cae4",
                500: "#00b4d8",
                600: "#0096c7",
                700: "#0077b6",
                800: "#023e8a",
                900: "#03045e",
            },
            turquoise: {
                100: "#80ffdb",
                200: "#72efdd",
                300: "#64dfdf",
                400: "#56cfe1",
                500: "#48bfe3",
                600: "#4ea8de",
                700: "#5390d9",
                800: "#5e60ce",
                900: "#6930c3",
            },
            gold: {
                100: "#ffd500",
                200: "#ffd500",
                300: "#ffd500",
                400: "#ffd500",
                500: "#fdc500",
            },
        },
    },
};

export { light };
