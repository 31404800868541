import React from "react";

import Head from "next/head";
import { AppProps } from "next/app";

import "tailwindcss/tailwind.css";
import { StyledThemeProvider } from "@definitions/styled-components";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "@redux/store";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "@definitions/chakra/theme";
import GlobalStyles from "@styles/globalStyles_sc";
import "@styles/globalStyles.css";
import RoboEpicsAuthHandler from "@util/RoboEpicsAuthHandler";
import EmotionRtlProvider from "@util/EmotionRtlProvider";

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    return (
        <ChakraProvider theme={theme}>
            <StyledThemeProvider>
                <Provider store={store}>
                    <PersistGate persistor={persistor}>
                        <EmotionRtlProvider>
                            <RoboEpicsAuthHandler>
                                <>
                                    <GlobalStyles />

                                    <Head>
                                        <meta
                                            name="viewport"
                                            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                                        />
                                        <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
                                        <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
                                        <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
                                        <link rel="manifest" href="/icons/site.webmanifest" />
                                        <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5bbad5" />
                                        <meta name="msapplication-TileColor" content="#da532c" />
                                        <meta name="theme-color" content="#ffffff" />
                                    </Head>
                                    <Component {...pageProps} />
                                </>
                            </RoboEpicsAuthHandler>
                        </EmotionRtlProvider>
                    </PersistGate>
                </Provider>
            </StyledThemeProvider>
        </ChakraProvider>
    );
}

export default MyApp;
// export default appWithTranslation(MyApp);
