import { createSlice } from "@reduxjs/toolkit";

export interface ICompetition {
    id: number | string;
    path: string;
    participantId: string;
    alerts: Array<"registerSuccess" | "inviteAvailable">;
}

const initialState: ICompetition = {
    id: null,
    path: null,
    participantId: null,
    alerts: [],
};

const competitionSlice = createSlice({
    name: "competition",
    initialState,
    reducers: {
        setId: (state, data) => {
            state.id = data.payload;
        },
        setPath: (state, data) => {
            state.path = data.payload;
        },
        setParticipantId: (state, data) => {
            state.participantId = data.payload;
        },
        removeAlert: (state, data) => {
            state.alerts = state.alerts.filter((element) => element !== data.payload);
        },
        addAlert: (state, data) => {
            if (!state.alerts.includes(data.payload)) state.alerts = [...state.alerts, data.payload];
        },
    },
});

export const { setId, setPath, setParticipantId, removeAlert, addAlert } = competitionSlice.actions;

export default competitionSlice.reducer;
