import { getImageURL } from "@util/mediaLoader";
import { store } from "@redux/store";
import { setUserData } from "@redux/actions";
import { fetchWithRefresh } from "@util/api/base";
import { IServerSideContext } from "@util/interfaces";

export const retrieveUserProfile = async (username: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/users/username/${username}`, {}, "preferred", ctx);

export const retrieveUserProfileById = async (id: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/users/${id}/`, {}, "preferred", ctx);

export const retrieveMyProfile = async (ctx?: IServerSideContext) => await fetchWithRefresh("/account/profile", {}, "required", ctx);

export const editMyProfile = async (form: FormData, ctx?: IServerSideContext) =>
    await fetchWithRefresh("/account/profile", { body: form, method: "PATCH" }, "required", ctx);

export const removeProfilePicture = async (ctx?: IServerSideContext) =>
    await fetchWithRefresh("/account/profile/remove-picture", { method: "DELETE" }, "required", ctx);

export const getNotificationsCount = async (ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/profile/notifications/count`, {}, "required", ctx);

export const getNotifications = async (limit: number, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/profile/notifications?limit=${limit}`, {}, "required", ctx);

export const markNotificationsAsRead = async (ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/profile/notifications/mark-as-read`, { method: "POST" }, "required", ctx);

export const retrieveUserActivities = async (fusion_user_id: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/users/${fusion_user_id}/activities`, {}, "required", ctx);

export const retrieveUserCompetitions = async (fusion_user_id: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/users/${fusion_user_id}/competitions`, {}, "required", ctx);

export const retrieveHostedCompetitions = async (fusion_user_id: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/users/${fusion_user_id}/competitions_hosted`, {}, "required", ctx);

export const reportUser = async (fusion_user_id: string, subject: string, description: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/account/users/${fusion_user_id}/report`,
        {
            method: "POST",
            body: JSON.stringify({ subject, description }),
            headers: { "Content-Type": "application/json" },
        },
        "required",
        ctx,
    );

export const sendVerificationCode = async (phone_number: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/account/profile/phone_number`,
        {
            method: "POST",
            body: JSON.stringify({ phone_number }),
            headers: { "Content-Type": "application/json" },
        },
        "required",
        ctx,
    );

export const checkVerificationCode = async (phone_number: string, security_code: string, session_token: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/account/profile/phone_number/verify`,
        {
            method: "POST",
            body: JSON.stringify({ phone_number, security_code, session_token }),
            headers: { "Content-Type": "application/json" },
        },
        "required",
        ctx,
    );

export const updateProfileState = async (ctx?: IServerSideContext) => {
    const data = (await retrieveMyProfile(ctx)) as Response;
    if (data) {
        const userData = await data.json();
        store.dispatch(
            setUserData({
                id: userData.fusion_user_id,
                fullName: userData.full_name,
                username: userData.username,
                imageUrl: getImageURL(userData.profile_picture),
                email: userData.email,
                isSuperuser: userData.is_superuser,
            }),
        );
        return userData;
    }
};

export const searchUserByUsernameOrEmail = async (email?: string, username?: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/users?username=${username}&email=${email}`, {}, "preferred", ctx);

export const searchUserByUsername = async (username: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/users?username=${username}`, {}, "preferred", ctx);

export const searchUserByEmail = async (email: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/users?email=${email}`, {}, "preferred", ctx);

export const searchUserByFullname = async (full_name: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/users?full_name=${full_name}`, {}, "preferred", ctx);

export const getUserNotebooks = async (userId: string | string[], ctx?: IServerSideContext) =>
    await fetchWithRefresh(`/account/users/${userId}/notebooks`, {}, "required", ctx);
