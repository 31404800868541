import Router from "next/router";
import { refreshAccessToken } from "@util/RoboEpicsAuthHandler";
import { IServerSideContext } from "@util/interfaces";
import { ROBOEPICS_API } from "@util/constants";
import { Redirect } from "next";
import { error_500_toast_message, session_ended_toast_message } from "@util/strings";

export async function fetchWithRefresh(
    path: string,
    options: RequestInit = {},
    withAuthorization: "preferred" | "required" | "off" = "preferred",
    ctx?: IServerSideContext,
    refreshAndRetry = true,
): Promise<Response | null | Redirect> {
    options.headers = options.headers || {};
    const requesterPath = (ctx?.requesterPath || "").replaceAll(/&/g, "%26");

    if (withAuthorization !== "off" && !options.headers["Authorization"]) {
        // Check or refresh access token
        const refreshAccessTokenResponse = await refreshAccessToken(ctx, withAuthorization === "required");
        if (refreshAccessTokenResponse == null && withAuthorization === "required") return null;
        if (refreshAccessTokenResponse != null) options.headers["Authorization"] = "Bearer " + refreshAccessTokenResponse.accessToken;
    }

    const toastId = path || "general-toast-id";
    const isToastActive = !ctx?.toast?.isActive(toastId);
    const toastTitle =
        options.method ??
        "GET " +
            path
                .split("/")
                .filter((word) => word.length > 0)
                .join("-");

    let res: Response;
    try {
        res = await fetch(ROBOEPICS_API + path, options);
    } catch (err) {
        if (ctx?.toast && isToastActive) {
            ctx.toast({
                title: toastTitle,
                description: "مشکلی در ارتباط با سرور به وجود آمده؛ لطفاً از برقراری ارتباط خود با اینترنت اطمینان حاصل فرمایید.",
                status: "error",
                duration: 5000,
                isClosable: true,
                variant: "subtle",
            });
            return;
        }
    }

    if (res.status === 401) {
        if (refreshAndRetry) {
            return fetchWithRefresh(path, options, withAuthorization, ctx, false);
        }

        if (ctx && ctx.res) {
            return {
                destination: `/users/signin${ctx?.requesterPath ? `?returnUrl=${ctx.requesterPath}` : ""}`,
                statusCode: 302,
            };
        } else {
            // There was something wrong with refreshing the token, so we redirect to sign-in
            if (isToastActive) {
                ctx.toast({
                    title: toastTitle,
                    description: session_ended_toast_message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                    variant: "solid",
                });
            }
            setTimeout(() => {
                Router.push(`/users/signin?returnUrl=${requesterPath}`);
            }, 1500);
        }
    } else if (res.status === 500) {
        if (isToastActive) {
            ctx.toast({
                title: toastTitle,
                description: error_500_toast_message,
                status: "error",
                duration: 5000,
                isClosable: true,
                variant: "solid",
            });
        }
    }
    return res;
}
