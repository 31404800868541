// eslint-disable-next-line
// @ts-nocheck
import { extendTheme } from "@chakra-ui/react";
import styles from "./styles";
import colors from "./foundations/colors";
import fontSizes from "./foundations/fontSizes";
import fonts from "./foundations/fonts";
import colorMode from "./foundations/colorMode";
import components from "./foundations/components";
import { StepsStyleConfig } from "chakra-ui-steps";

const CustomSteps = {
    ...StepsStyleConfig,
    baseStyle: (props) => {
        return {
            ...StepsStyleConfig.baseStyle(props),
            connector: {
                ...StepsStyleConfig.baseStyle(props).connector,
                position: "relative",
            },
        };
    },
};
const overrides = {
    ...styles,
    colors,
    fonts,
    // fontSizes,
    colorMode,
    components: { ...components, Steps: CustomSteps },
    direction: "rtl",
};

const theme = extendTheme(overrides);

export default theme;
