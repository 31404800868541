import axios from "axios";
import qs from "qs";
import * as Constants from "@util/constants";
import { fetchWithRefresh } from "./base";
import { IServerSideContext } from "@util/interfaces";

export const UserLogin = async (username: string, password: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        "/account/login",
        {
            method: "POST",
            body: JSON.stringify({ username, password }),
            headers: { "Content-Type": "application/json" },
        },
        "off",
        ctx,
    );

export const resendVerificationEmail = async (loginId: string, ctx?: IServerSideContext) =>
    await fetchWithRefresh(
        `/account/resend-verify`,
        {
            method: "POST",
            body: JSON.stringify({ loginId }),
            headers: { "Content-Type": "application/json" },
        },
        "off",
        ctx,
    );

export const UserSignup = async (
    email: string,
    password: string,
    fullNameEn: string,
    fullNameFa: string,
    username: string,
    ctx?: IServerSideContext,
) =>
    await fetchWithRefresh(
        `/account/register`,
        {
            method: "POST",
            body: JSON.stringify({ email, password, full_name_english: fullNameEn, full_name: fullNameFa, username }),
            headers: { "Content-Type": "application/json" },
        },
        "off",
        ctx,
    );

export const UserForgotPassword = async (loginId: string) => {
    const result = await axios
        .post(
            `${Constants.FUSION_API}/user/forgot-password`,
            {
                loginId: loginId,
            },
            { headers: { Authorization: Constants.FUSION_API_KEY } },
        )
        .then((response) => response)
        .catch((err) => err.response);
    return result;
};

export const UserChangePassword = async (passwordChangeId: string | string[], password: string) => {
    const result = await axios
        .post(`${Constants.FUSION_API}/user/change-password/${passwordChangeId}`, {
            password: password,
        })
        .then((response) => response)
        .catch((err) => err.response);
    return result;
};

// export const UserLogout = async (accessToken: string, refreshToken: string) =>
//     await axios
//         .post(
//             `${Constants.FUSION_API}/logout?refreshToken=${refreshToken}`,
//             {},
//             {
//                 headers: {
//                     Cookie: `access_token=${accessToken}`,
//                 },
//             },
//         )
//         .then((response) => response)
//         .catch((err) => err.response);

export const getAccessTokenWithRefreshToken = async (
    refreshToken: string,
    accessToken: string = null,
): Promise<{ access_token: string; refresh_token: string } | null> => {
    const headers = { "Content-Type": "application/x-www-form-urlencoded", Cookie: undefined };
    if (accessToken !== null) headers.Cookie = "accessToken=" + accessToken;

    const data = qs.stringify({
        client_id: Constants.FUSION_APPLICATION_ID,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
    });

    try {
        const serverResponse = await fetch(Constants.FUSION_OAUTH + "/token", {
            method: "POST",
            headers,
            body: data,
        });
        const resolved = await serverResponse.json();
        if (serverResponse.ok) return resolved;
        else {
            console.log("data sent: ", data);
            console.log("error on getAccessTokenWithRefreshToken: ", resolved);
        }
    } catch (e) {
        console.log("error on getAccessTokenWithRefreshToken: ", e);
    }
    return null;
};
