export default {
    brand: {
        900: "#1a365d",
        800: "#153e75",
        700: "#2a69ac",
    },
    header: {
        100: "#20232a",
    },
    main: {
        100: "#282c34",
    },
    RoboEpics: {
        black: {
            default: "#000",
            900: "#18181B",
            800: "#343a40",
            700: "#495057",
            600: "#6c757d",
            500: "#adb5bd",
            400: "#ced4da",
            300: "#dee2e6",
            200: "#e9ecef",
            100: "#f8f9fa",
        },
        azure: {
            100: "#caf0f8",
            200: "#ade8f4",
            300: "#90e0ef",
            400: "#48cae4",
            500: "#00b4d8",
            600: "#0096c7",
            700: "#0077b6",
            800: "#023e8a",
            900: "#03045e",
        },
        turquoise: {
            100: "#80ffdb",
            200: "#72efdd",
            300: "#64dfdf",
            400: "#56cfe1",
            500: "#48bfe3",
            600: "#4ea8de",
            700: "#5390d9",
            800: "#5e60ce",
            900: "#6930c3",
        },
        gold: {
            50: "#ffee9a",
            100: "#ffea81",
            200: "#ffe562",
            300: "#ffde3b",
            400: "#ffd500",
            500: "#fdc500",
            600: "#e6b300",
            700: "#d1a300",
            800: "#be9400",
            900: "#ad8700",
        },
        green: {
            100: "#ccff33",
            200: "#9ef01a",
            300: "#70e000",
            400: "#38b000",
            500: "#008000",
            600: "#007200",
            700: "#006400",
            800: "#004b23",
        },
        dark: {
            100: "#3B4956",
            200: "#34414C",
            300: "#2E3943",
            400: "#273139",
            500: "#212930",
            600: "#1A2026",
            700: "#14181D",
            800: "#0D1013",
            900: "#07080A",
        },
    },
    xero: {
        green: {
            50: "#9bf399",
            100: "#82f080",
            200: "#68ec66",
            300: "#4fe94d",
            400: "#36e633",
            500: "#04e000",
            600: "#04ca00",
            700: "#03b300",
            800: "#039d00",
            900: "#028600",
        },
        blue: {
            50: "#99aad0",
            100: "#8095c5",
            200: "#667fb9",
            300: "#4d6aad",
            400: "#3355a1",
            500: "#002a8a",
            600: "#00267c",
            700: "#00226e",
            800: "#001f61",
            900: "#001953",
        },
        dark: {
            50: "#a7a7a7",
            100: "#929292",
            200: "#7c7c7c",
            300: "#666666",
            400: "#505050",
            500: "#242424",
            600: "#202020",
            700: "#1d1d1d",
            800: "#191919",
            900: "#161616",
        },
    },
};
