import { ThemeOverride } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

type GlobalStyles = Pick<ThemeOverride, "styles">;

export default {
    styles: {
        global: (props) => ({
            body: {
                bg: mode("#F7F7F8", "#14181d")(props),
                fontSize: "14px",
            },
            h4: {
                marginTop: "0.1em",
                fontSize: "1.15em",
                fontWeight: "600",
            },
            h3: {
                fontSize: "1.3em",
                fontWeight: "600",
            },
            h2: {
                marginBottom: "0.5em",
                fontSize: "1.5em",
                fontWeight: "600",
            },
            h1: {
                fontSize: "1.875em",
                fontWeight: 700,
                marginBottom: "0.5em",
            },
            p: {
                marginBottom: "1em",
                fontSize: "14px",
            },
            // span: {
            //     fontSize: "14px",
            // },
            a: {
                fontSize: "14px",
                fontWeight: 100,
                textDecoration: "none",
            },
            ".katex": {
                font: "normal 1.21em KaTeX_Main, Times New Roman, serif",
            },
        }),
    },
} as GlobalStyles;
