import React from "react";
import { Book } from "react-feather";
import {
    PeopleOutline,
    BarChartOutline,
    ChatbubblesOutline,
    RadioOutline,
    ReaderOutline,
    ShieldCheckmarkOutline,
    DocumentAttachOutline,
    CloudUploadOutline,
    LogoGithub,
    LogoInstagram,
    LogoLinkedin,
    LogoTwitter,
    SettingsOutline,
    CheckboxOutline,
} from "react-ionicons";

export const ROBOEPICS_API = process.env.NEXT_PUBLIC_ROBOEPICS_API || "https://api.roboepics.com";

export const FUSION_API = "https://fusion.roboepics.com/api";
export const FUSION_OAUTH = "https://fusion.roboepics.com/oauth2";
export const FUSION_ACCESS_TOKEN_EXPIRE_MINUTES = 30;
export const FUSION_APPLICATION_ID = "7126a051-baea-4fe1-bdf8-fde2fdb31f97"; // INCLUDE THIS IN REQUEST BODY
export const FUSION_API_KEY = "_KF3413_NAZ08qGbjO0WDT_lAIporc3UAAOaXgIn3yyvzepG6LDZ2gjQ"; // INCLUDE THIS IN REQUEST HEADER AS "authorization" VALUE

// export const MEDIA_STORAGE_URL_MINIO = "https://s3.ir-thr-at1.arvanstorage.com";
export const MEDIA_STORAGE_URL_MINIO = "https://minio.roboepics.com";

export const ARVAN_SINGLE_UPLOAD_MAX_SIZE = 350 * 1024 * 1024;

export const LEADERBOARD_ITEMS_PER_PAGE = 25;
export const COMPETITION_PARTICIPANT_ITEMS_PER_PAGE = 25;
export const SUBMISSIONS_ITEMS_PER_PAGE = 10;

export const ROBOEPICS_DISCORD_LINK = "https://discord.gg/8DMfjmn6gc";
export const ROBOEPICS_GITHUB_LINK = "https://github.com/RoboEpics";
export const ROBOEPICS_IG_LINK = "https://instagram.com/roboepics_com";
export const ROBOEPICS_YT_LINK = "https://www.youtube.com/channel/UCJszsUX1CbsUm_DjBOyBjFQ";
export const ROBOEPICS_TELEGRAM_LINK = "https://t.me/roboepics";
export const ROBOEPICS_LINKEDIN_LINK = "https://ir.linkedin.com/company/roboepics?trk=ppro_cprof";

export const DISCOURSE_API = "https://discourse.roboepics.com";
export const DISCOURSE_API_KEY = "b0a96e05d58807f6c84c5476ea1de68a12dcf5a3de3594811b6e38c926936831";
export const DISCOURSE_API_USER = "system";

export const JALALI_MONTH_NAMES = {
    1: "فروردین",
    2: "اردیبهشت",
    3: "خرداد",
    4: "تیر",
    5: "مرداد",
    6: "شهریور",
    7: "مهر",
    8: "آبان",
    9: "آذر",
    10: "دی",
    11: "بهمن",
    12: "اسفند",
};

export const PROBLEM_TABS = {
    0: {
        label: "توضیحات",
        key: "overview",
        icon: <ReaderOutline />,
    },
    1: {
        label: "داده‌ها",
        key: "dataset",
        icon: <DocumentAttachOutline />,
    },
    2: {
        label: "ارسال",
        key: "submissions",
        icon: <CloudUploadOutline />,
    },
    3: {
        label: "امتیازها",
        key: "scoreboard",
        icon: <BarChartOutline />,
    },
    // 4: {
    //     label: "نوت‌بوک‌ها",
    //     key: "notebooks",
    //     icon: <Book className="ml-2 flex-shrink-0 stroke-1" />,
    // },
    // 5: {
    //     label: "گفت‌و‌گو",
    //     key: "discussion",
    // },
};

export const COMPETITION_TABS = {
    0: {
        label: "توضیحات",
        key: "overview",
        icon: <ReaderOutline />,
    },
    1: {
        label: "تیم",
        key: "team",
        icon: <PeopleOutline />,
    },
    2: {
        label: "امتیازها",
        key: "scoreboard",
        icon: <BarChartOutline />,
    },
    3: {
        label: "اعلان‌ها",
        key: "announcements",
        icon: <RadioOutline />,
    },
    4: {
        label: "آیین‌نامه",
        key: "rules",
        icon: <ShieldCheckmarkOutline />,
    },
};

export const USER_SOCIALS = {
    twitter: {
        label: "Twitter",
        iconElement: <LogoTwitter />,
    },
    github: {
        label: "Github",
        iconElement: <LogoGithub />,
    },
    instagram: {
        label: "Instagram",
        iconElement: <LogoInstagram />,
    },
    linkedin: {
        label: "Linkedin",
        iconElement: <LogoLinkedin />,
    },
};

export const SE_FA = {
    toolbar: {
        default: "پیش‌فرض",
        save: "ذخیره",
        font: "قلم",
        formats: "Formats",
        fontSize: "Size",
        bold: "ضخیم",
        underline: "Underline",
        italic: "Italic",
        strike: "Strike",
        subscript: "Subscript",
        superscript: "Superscript",
        removeFormat: "Remove Format",
        fontColor: "Font Color",
        hiliteColor: "Hilite Color",
        indent: "Indent",
        outdent: "Outdent",
        align: "Align",
        alignLeft: "Align left",
        alignRight: "Align right",
        alignCenter: "Align center",
        alignJustify: "Align justify",
        list: "list",
        orderList: "Ordered list",
        unorderList: "Unordered list",
        horizontalRule: "horizontal line",
        hr_solid: "solid",
        hr_dotted: "dotted",
        hr_dashed: "dashed",
        table: "Table",
        link: "Link",
        image: "Image",
        video: "Video",
        fullScreen: "Full screen",
        showBlocks: "Show blocks",
        codeView: "Code view",
        undo: "Undo",
        redo: "Redo",
        preview: "Preview",
        print: "print",
        tag_p: "Paragraph",
        tag_div: "Normal (DIV)",
        tag_h: "Header",
        tag_blockquote: "Quote",
        tag_pre: "Code",
        template: "Template",
    },

    dialogBox: {
        linkBox: {
            title: "درج لینک",
            url: "آدرس لینک",
            text: "متن نمایشی",
            newWindowCheck: "باز شدن در پنجره جدید",
        },

        imageBox: {
            title: "درج عکس",
            file: "فراخوانی فایل",
            url: "آدرس عکس",
            altText: "متن جایگزین",
        },

        videoBox: {
            title: "Insert Video",
            url: "Media embed URL, YouTube",
        },

        caption: "درج توضیحات",
        close: "انصراف",
        submitButton: "ادامه",
        revertButton: "Revert",
        proportion: "حفظ ابعاد تصویر",
        width: "طول",
        height: "عرض",
        basic: "ساده",
        left: "چپ",
        right: "راست",
        center: "مرکز",
    },

    controller: {
        edit: "Edit",
        remove: "Remove",
        insertRowAbove: "Insert row above",
        insertRowBelow: "Insert row below",
        deleteRow: "Delete row",
        insertColumnBefore: "Insert column before",
        insertColumnAfter: "Insert column after",
        deleteColumn: "Delete column",
        resize100: "Resize 100%",
        resize75: "Resize 75%",
        resize50: "Resize 50%",
        resize25: "Resize 25%",
        mirrorHorizontal: "Mirror, Horizontal",
        mirrorVertical: "Mirror, Vertical",
        rotateLeft: "Rotate left",
        rotateRight: "Rotate right",
        maxSize: "Max size",
        minSize: "Min size",
        tableHeader: "Table header",
        mergeCells: "Merge cells",
        splitCells: "Split Cells",
        HorizontalSplit: "Horizontal split",
        VerticalSplit: "Vertical split",
    },
};

export const RUN_STATUS = {
    10: {
        label: "در حال آماده‌سازی اجرا",
        colorScheme: "orange",
        step: "4",
    },
    20: {
        label: "خطای داخلی سیستم",
        colorScheme: "red",
        step: "4",
    },
    30: {
        label: "پروسه آماده‌سازی اجرا در صف قرار گرفته",
        colorScheme: "orange",
        step: "5",
    },
    40: {
        label: "پروسه آماده‌سازی اجرا آغاز شده",
        colorScheme: "blue",
        step: "5",
    },
    50: {
        label: "پروسه آماده‌سازی اجرا با خطا مواجه شده",
        colorScheme: "red",
        step: "5",
    },
    60: {
        label: "پروسه آماده‌سازی اجرا با موفقیت انجام شده",
        colorScheme: "orange",
        step: "5",
    },
    70: {
        label: "اجرا در صف قرار گرفته",
        colorScheme: "orange",
        step: "6",
    },
    80: {
        label: "اجرا آغاز شده",
        colorScheme: "blue",
        step: "7",
    },
    90: {
        label: "اجرا با خطا مواجه شده",
        colorScheme: "red",
        step: "7",
    },
    100: {
        label: "اجرا با موفقیت انجام شده",
        colorScheme: "green",
        step: "7",
    },
};

export const SUBMISSION_STATUS = {
    10: {
        label: "ارسال در صف قرار گرفته",
        colorScheme: "orange",
        step: "1",
    },
    20: {
        label: "پروسه ساخت ایمیج در صف قرار گرفته",
        colorScheme: "orange",
        step: "2",
    },
    30: {
        label: "پروسه ساخت ایمیج آغاز شده",
        colorScheme: "blue",
        step: "2",
    },
    40: {
        label: "پروسه ساخت ایمیج با خطا مواجه شده",
        colorScheme: "red",
        step: "2",
    },
    50: {
        label: "پروسه ساخت ایمیج با موفقیت انجام شده",
        colorScheme: "orange",
        step: "2",
    },
    60: {
        label: "پروسه ذخیره ایمیج با خطا مواجه شده",
        colorScheme: "red",
        step: "3",
    },
    100: {
        label: "ثبت موفقیت‌آمیز ارسال",
        colorScheme: "green",
        step: "3",
    },
};

export const PROBLEM_SETTING_TABS_ADVANCED = {
    0: { label: "تنظیمات کلی", key: "Basic" },
    1: { label: " ارزیاب", key: "Evaluation" },
    2: { label: "همیاران", key: "Collaborators" },
    3: { label: "Gimulator", key: "Gimulator" },
    4: { label: "مخازن چالش", key: "Repositories" },
    5: { label: "تعریف امتیازها", key: "Scores Definition" },
    6: { label: "جدول امتیازها", key: "Leaderboard" },
    // 3: { label: "Resource Limit", key: "Recource" },
};

export const PROBLEM_SETTING_TABS_SIMPLE = {
    0: { label: " ارزیاب", key: "Evaluation" },
    1: { label: "تعریف امتیازها", key: "Scores Definition" },
    2: { label: "همیاران", key: "Collaborators" },
};

export const OWNER_COMPETITION_TABS = {
    0: {
        label: "تنظیمات",
        key: "settings",
        icon: <SettingsOutline />,
    },
    1: { label: "انتشار", key: "checklist", icon: <CheckboxOutline /> },
    2: { label: "برگزارکنندگان", key: "organizers", icon: <PeopleOutline /> },
    3: {
        label: "توضیحات",
        key: "overview",
        icon: <ReaderOutline />,
    },
    4: {
        label: "شرکت‌کنندگان",
        key: "team",
        icon: <PeopleOutline />,
    },
    5: { label: "امتیازها", key: "scoreboard", icon: <BarChartOutline /> },
    6: {
        label: "اعلان‌ها",
        key: "announcements",
        icon: <RadioOutline />,
    },
    7: {
        label: "آیین‌نامه",
        key: "rules",
        icon: <ShieldCheckmarkOutline />,
    },
};

export const PROBLEM_SCORING_METRICS = [{ title: "F1", desc: "" }];

export const MAXIMUM_CPU_CORES = 4; //CORE
export const MAXIMUM_RAM_MEMORY = 4096; //MB
export const MAXIMUM_EPHEMERAL_MEMORY = 10240; //MB

export const DATASET_TABS = {
    0: { label: "داده‌ها", key: "dataset", icon: <DocumentAttachOutline /> },
    1: {
        label: "نوت‌بوک‌ها",
        key: "notebooks",
        icon: <Book className="ml-2 flex-shrink-0 stroke-1" />,
    },
};

export const DATASET_LICENSES = [
    {
        label: "Creative Commons",
        options: [
            {
                label: "CC0: Public Domain",
                value: "CC0: Public Domain",
                link: "https://creativecommons.org/publicdomain/zero/1.0/",
            },
            {
                label: "CC BY-NC-SA 4.0",
                value: "CC BY-NC-SA 4.0",
                link: "https://creativecommons.org/licenses/by-nc-sa/4.0/",
            },
            {
                label: "CC BY-SA 4.0",
                value: "CC BY-SA 4.0",
                link: "https://creativecommons.org/licenses/by-sa/4.0",
            },
            {
                label: "CC BY-SA 3.0",
                value: "CC BY-SA 3.0",
                link: "https://creativecommons.org/licenses/by-sa/3.0",
            },
            {
                label: "Attribution 4.0 Internaional (CC BY 4.0)",
                value: "Attribution 4.0 Internaional (CC BY 4.0)",
                link: "https://creativecommons.org/licenses/by/4.0",
            },
            {
                label: "Attribution Non-Commercial 4.0 Internaional (CC BY-NC 4.0)",
                value: "Attribution Non-Commercial 4.0 Internaional (CC BY-NC 4.0)",
                link: "https://creativecommons.org/licenses/by-nc/4.0",
            },
            {
                label: "Attribution 3.0 Unported (CC BY 3.0)",
                value: "Attribution 3.0 Unported (CC BY 3.0)",
                link: "https://creativecommons.org/licenses/by/3.0",
            },
            {
                label: "Attribution 3.0 IGO (CC BY 3.0 IGO)",
                value: "Attribution 3.0 IGO (CC BY 3.0 IGO)",
                link: "https://creativecommons.org/licenses/by/3.0/igo/",
            },
            {
                label: "Attribution-NonCommercial-ShareAlike 3.0 IGO (CC BY-NC-SA 3.0 IGO)",
                value: "Attribution-NonCommercial-ShareAlike 3.0 IGO (CC BY-NC-SA 3.0 IGO)",
                link: "https://creativecommons.org/licenses/by-nc-sa/3.0/igo/",
            },
            {
                label: "Attribution-NonDerivatives 4.0 Internaional (CC BY-ND 4.0)",
                value: "Attribution-NonDerivatives 4.0 Internaional (CC BY-ND 4.0)",
                link: "https://creativecommons.org/licenses/by-nd/4.0",
            },
            {
                label: "Attribution-NonCommercial-NonDerivatives 4.0 Internaional (CC BY-NC-ND 4.0)",
                value: "Attribution-NonCommercial-NonDerivatives 4.0 Internaional (CC BY-NC-ND 4.0)",
                link: "https://creativecommons.org/licenses/by-nc-nd/4.0",
            },
        ],
    },
    {
        label: "GPL",
        options: [
            {
                label: "GPL 2",
                value: "GPL 2",
                link: "https://www.gnu.org/licenses/old-licenses/gpl-2.0.en.html",
            },
            {
                label: "GNU Lesser General Public License 3.0",
                value: "GNU Lesser General Public License 3.0",
                link: "https://www.gnu.org/licenses/lgpl-3.0.en.html",
            },
            {
                label: "GNU Affero General Public License",
                value: "GNU Affero General Public License 3.0",
                link: "https://www.gnu.org/licenses/agpl-3.0.en.html",
            },
            {
                label: "GNU Free Documentaion License 1.3",
                value: "GNU Free Documentation License 1.3",
                link: "https://www.gnu.org/licenses/fdl-1.3.en.html",
            },
        ],
    },
    {
        label: "Open Data Commons",
        options: [
            {
                label: "Database: Open Database, Contents: Database Contents",
                value: "Database: Open Database, Contents: Database Contents",
                link: "https://opendatacommons.org/licenses/dbcl/1.0/",
            },
            {
                label: "Database: Open Database, Contents: \u00A9 Original Authors",
                value: "Database: Open Database, Contents: \u00A9 Original Authors",
                link: "https://opendatacommons.org/licenses/odbl/1.0/",
            },
            {
                label: "ODC Public Domain Dedication and License (PDDL)",
                value: "ODC Public Domain Dedication and License (PDDL)",
                link: "https://opendatacommons.org/licenses/pddl/1.0/",
            },
            {
                label: "ODC Attribution License (ODC-By)",
                value: "ODC Attribution License (ODC-By)",
                link: "https://opendatacommons.org/licenses/by/1.0/index.html",
            },
        ],
    },
    {
        label: "Community Data License",
        options: [
            {
                label: "Community Data License Agreement - permissive - Version 1.0",
                value: "Community Data License Agreement - permissive - Version 1.0",
                link: "https://cdla.io/permissive-1-0/",
            },
            {
                label: "Community Data License Agreement - Sharing - Version 1.0",
                value: "Community Data License Agreement - Sharing - Version 1.0",
                link: "https://cdla.io/sharing-1-0/",
            },
        ],
    },
    {
        label: "Special",
        options: [
            {
                label: "World Bank Dataset Terms of Use",
                value: "World Bank Dataset Terms of Use",
                link: "https://www.worldbank.org/en/about/legal/terms-of-use-for-datasets",
            },
            {
                label: "Reddit API Terms",
                value: "Reddit API Terms",
                link: "https://www.reddit.com/wiki/api",
            },
            {
                label: "U.S. Government Works",
                value: "U.S. Government Works",
                link: "https://www.usa.gov/government-works/",
            },
            {
                label: "EU ODP Legal Notice",
                value: "EU ODP Legal Notice",
                link: "https://ec.europa.eu/info/legal-notice_en",
            },
        ],
    },
    {
        label: "Other",
        options: [
            {
                label: "Data files \u00A9 Original Authors",
                value: "Data files \u00A9 Original Authors",
                link: null,
            },
            {
                label: "Other (Specified in description)",
                value: "Other (Specified in description)",
                link: null,
            },
            { label: "Unknown", value: "Unknown", link: null },
        ],
    },
];

export const COMPETITION_STATUS = {
    0: { key: "not_published", label: "منتشر نشده", color: "red" },
    10: { key: "before_register", label: "ثبت نام آغاز نشده", color: "yellow" },
    20: {
        key: "between_register_and_submission",
        label: "در حال ثبت نام شرکت‌کنندگان",
        color: "blue",
    },
    30: { key: "submission", label: "در حال برگزاری", color: "green" },
    40: { key: "after_submission", label: "به پایان رسیده", color: "gray" },
};

export const COLLABORATORS_ACCESS_LEVEL = {
    50: { key: "full_access", label: "دسترسی کامل", color: "green" },
    40: { key: "editor_access", label: "دسترسی ویرایشگر", color: "blue" },
};

export const ALLOWED_RUNTIMES = {
    Python310STDINBuildPack: { label: "Python 3.10", value: "Python310STDINBuildPack", fileFormats: ["py"] },
    NodeJS18STDINBuildPack: { label: "NodeJS 18", value: "NodeJS18STDINBuildPack", fileFormats: ["js"] },
    PHP8STDINBuildPack: { label: "PHP 8", value: "PHP8STDINBuildPack", fileFormats: ["php"] },
    Erlang25STDINBuildPack: { label: "Erlang 25", value: "Erlang25STDINBuildPack", fileFormats: ["erl"] },
    CPP12STDINBuildPack: { label: "C++ 12", value: "CPP12STDINBuildPack", fileFormats: ["cpp"] },
    other: { label: "Other", value: "other", fileFormats: [] },
};
