import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
*,
*::after,
*::before {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Shabnam', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

body, html {
    min-height: 100%;
}

#__next {
    height: 100%;
}
#__content {
    min-height: calc(100vh - 396px)
}

a{
    text-decoration: none;
    font-weight: initial;
}

button: focus {
    outline: none;
}

*: focus {
    box-shadow: none !important;
}

:root {
    --re-comp-card-items-per-row: 3;
    --re-comp-card-item-margin: 18px;
    --re-comp-card-item-max-width: 480px;
    --ck-font-face: 'Shabnam' !important;
}

@media screen and (min-width: 2560px) {
    :root {
        --re-comp-card-items-per-row: 4;
    }
}

.public-DraftEditor-content {
    min-height: 300px;
    padding: 15px;
    border-width: 1px
}


`;
